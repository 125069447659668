/* eslint-disable @typescript-eslint/no-unused-vars */
import { DependencyContainer } from '../../../../../DependencyContainer';
import { HttpClient } from '../../../../../HttpClient';
import { AxiosResponse } from 'axios';
import { ApiUrls } from '../../../../../config/urls';
import { getApiUrl } from '../../../../../config/environment';
import {Session } from '../types';
import { CreateLobbyPayload, ExistingLobbyPayload } from '../../Lobby/types';
import { config } from 'process';

const configOverride = {
  baseURL: getApiUrl(),
};

export default class SessionClient extends HttpClient {
  constructor(protected readonly factory: DependencyContainer) {
    super(factory);
  }

  async createSession(
    token: string,
    payload: ExistingLobbyPayload,
  ): Promise<AxiosResponse<Session>> {
    this.setToken(token);
    const response = await this.post<Session>(
      ApiUrls.getUrlForTeamSessionCreation(),
      payload,
      configOverride,
    );
    return response;
  }

  async createSoloSession(
    token: string,
    payload: CreateLobbyPayload,
  ): Promise<AxiosResponse<Session>> {
    this.setToken(token);
    const response = await this.post<Session>(
      ApiUrls.getUrlForSoloSessionCreation(),
      payload,
      configOverride,
    );
    return response;
  }

}
